import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFullMobileSearchBoxOpen, setSearchBoxHidden } from "reduxStore/slices/nav-bar/actions";
import { DivProps } from "types/dom";

import BasketButtonFloating from "components/molecules/BasketButtonFloating";
import CustomCookieConsent from "components/molecules/CustomCookieConsent";
import NavBar from "components/organisms/NavBar";


const HIDDEN_SEARCH_BOX_SCROLL_LIMIT = 800;


const HomeBaseLayout = ({ children }: DivProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchBoxHidden(true));
    dispatch(setFullMobileSearchBoxOpen(false));
  }, []);

  return <div className="flex flex-col w-screen h-dvh overflow-hidden">
    <NavBar />
    <CustomCookieConsent/>
    <div className="flex flex-col w-full h-full grow-1 relative">
      <div
        id="contentScrollView"
        className="flex flex-col justify-between grow-1 w-full h-0 overflow-auto items-center"
        onScroll={e => {
          if (e.currentTarget.scrollTop > HIDDEN_SEARCH_BOX_SCROLL_LIMIT) {
            dispatch(setSearchBoxHidden(false));
          } else {
            dispatch(setSearchBoxHidden(true));
            dispatch(setFullMobileSearchBoxOpen(false));
          }
        }}
      >
        <div className="flex flex-col items-center w-full grow-1">
          {children}
          {/* <SaleBanner /> */}
        </div>
        <BasketButtonFloating/>
      </div>
    </div>
  </div>;
};

export default HomeBaseLayout;